import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby";
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import "./tos.css"; // Import CSS file here


export default function Terms() {
    return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Terms of Service</title>
              <script src={withPrefix('js/tos.js')} type="text/javascript" />
            </Helmet>
            <header>
                <img src="/img/logo.png" alt="Flair Wallpapers Logo" />
            </header>
            <div name="termly-embed" data-id="c37b8dda-9bbd-4c6b-94a7-be9bb6212543"></div>
            <RawFooter />
        </HomepageLayout>
    );
}
